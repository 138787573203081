import React, { FC } from 'react';
import { Link } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import RelatedProductCardTitle from './RelatedProductCardTitle';

import { IRelatedProductCardProps } from './models';
import './RelatedProductCard.scss';

const RelatedProductCard: FC<IRelatedProductCardProps> = ({
  productCard,
  relatedProductsCardCtaButton,
}) => (
  <div className="related-product-card">
    {productCard.properties?.amountCondoms ? (
      <div className="related-product-card__pack">{productCard.properties?.amountCondoms}</div>
    ) : null}

    {productCard.properties.image[0].localImage && (
      <Link to={productCard.url} className="related-product-card__link">
        <GatsbyImage
          isLazyLoading
          wrapperClasses="product-card__image"
          fluid={productCard.properties.image[0].localImage?.childImageSharp.fluid}
          alt={productCard.properties.image[0].properties.imageAltLabel}
        />
        {productCard?.properties?.titleRte ? (
          <DangerouslySetInnerHtml
            html={productCard.properties.titleRte}
            className="related-product-card__title"
          />
        ) : (
          <RelatedProductCardTitle title={productCard.properties.title} />
        )}
      </Link>
    )}
    {productCard.properties?.productFeatureDescription ? (
      <div className="related-product-card__description">
        {productCard.properties?.productFeatureDescription}
      </div>
    ) : null}
    <div className="related-product-card__CTA">
      <Button btnColorVariant="dark" link={productCard.url}>
        {relatedProductsCardCtaButton}
      </Button>
    </div>
  </div>
);

export default RelatedProductCard;
